import { boolean } from 'yup';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/common/redux/store';
import {
  ICountryTableValue,
  IFormCreateProvince,
  IFormCreateSkus,
  IFormCreateStores,
  StateProps,
} from './interface';
import { GridRowId } from '@mui/x-data-grid';

const initialState: StateProps = {
  fields: [],
  formStartDate: null,
  formEndDate: null,
  provinceInFo: {},
  storeInFo: {},
  skuInFo: {},
  isStoreExclusion: false,
  isStoreGroupExclusion: false,
  isCustomerExclusion: false,
  isCustomerGroupExclusion: false,
  crmTypeIdEdit: 0,
  giftIdEdit: 0,
  openConfirmDelete: false,
  openConfirmDeleteStore: false,
  openConfirmDeleteSku: false,
  idPrizeDelete: 0,
  countPrizeEvent: 0,
  countPrizeProvince: 0,
  countPrizeStore: 0,
  countPrizeSku: 0,
  rowProvinceId: null,
  rowStoreId: null,
  rowSkuId: null,
  statusPrize: true,
  prizeQuantityChange: null,
  openEditModal: false,
  confirmEdit: false,
  statusSupplierNull: false,
  statusBlacklist: false,
};

export const eventPrizeQ1Slice = createSlice({
  name: 'event-q1',
  initialState,
  reducers: {
    setFormStartDate: (state, action: PayloadAction<string | null>) => {
      state.formStartDate = action.payload;
    },
    setFormEndDate: (state, action: PayloadAction<string | null>) => {
      state.formEndDate = action.payload;
    },
    handleAddNewRow: (state, action: PayloadAction<ICountryTableValue>) => {
      state.fields.push(action.payload);
    },
    handleRemoveRow: (state, action: PayloadAction<string>) => {
      state.fields = state.fields.filter((item) => item?.id !== action.payload);
    },
    setProvinceInFo: (state, action: PayloadAction<IFormCreateProvince>) => {
      state.provinceInFo = action.payload;
    },
    setStoreInFo: (state, action: PayloadAction<IFormCreateStores>) => {
      state.storeInFo = action.payload;
    },
    setSkuInFo: (state, action: PayloadAction<IFormCreateSkus>) => {
      state.skuInFo = action.payload;
    },
    setIsStoreExclusion: (state, action: PayloadAction<boolean>) => {
      state.isStoreExclusion = action.payload;
    },
    setIsStoreGroupExclusion: (state, action: PayloadAction<boolean>) => {
      state.isStoreGroupExclusion = action.payload;
    },
    setIsCustomerExclusion: (state, action: PayloadAction<boolean>) => {
      state.isCustomerExclusion = action.payload;
    },
    setIsCustomerGroupExclusion: (state, action: PayloadAction<boolean>) => {
      state.isCustomerGroupExclusion = action.payload;
    },
    setCrmTypeIdEdit: (state, action: PayloadAction<number>) => {
      state.crmTypeIdEdit = action.payload;
    },
    setGiftIdEdit: (state, action: PayloadAction<number>) => {
      state.giftIdEdit = action.payload;
    },
    setOpenConfirmDelete: (state) => {
      state.openConfirmDelete = true;
    },
    setOpenConfirmDeleteStore: (state) => {
      state.openConfirmDeleteStore = true;
    },
    setOpenConfirmDeleteSku: (state) => {
      state.openConfirmDeleteSku = true;
    },
    setCloseConfirmDeleteStore: (state) => {
      state.openConfirmDeleteStore = false;
    },
    setCloseConfirmDelete: (state) => {
      state.openConfirmDelete = false;
    },
    setCloseConfirmDeleteSku: (state) => {
      state.openConfirmDeleteSku = false;
    },
    setIdPrizeDelete: (state, action: PayloadAction<number>) => {
      state.idPrizeDelete = action.payload;
    },
    setCountPrizeEvent: (state, action: PayloadAction<number>) => {
      state.countPrizeEvent = action.payload;
    },
    setCountPrizeProvince: (state, action: PayloadAction<number>) => {
      state.countPrizeProvince = action.payload;
    },
    setCountPrizeStore: (state, action: PayloadAction<number>) => {
      state.countPrizeStore = action.payload;
    },
    setCountPrizeSku: (state, action: PayloadAction<number>) => {
      state.countPrizeSku = action.payload;
    },
    setRowProvinceId: (state, action: PayloadAction<GridRowId | null>) => {
      state.rowProvinceId = action.payload;
    },
    setRowStoreId: (state, action: PayloadAction<GridRowId | null>) => {
      state.rowStoreId = action.payload;
    },
    setRowSkuId: (state, action: PayloadAction<GridRowId | null>) => {
      state.rowSkuId = action.payload;
    },
    setStatusPrize: (state, action: PayloadAction<boolean>) => {
      state.statusPrize = action.payload;
    },
    setPrizeQuantityChange: (state, action: PayloadAction<number | null>) => {
      state.prizeQuantityChange = action.payload;
    },
    setOpenEditModal: (state, action: PayloadAction<boolean>) => {
      state.openEditModal = action.payload;
    },
    setConfirmEdit: (state, action: PayloadAction<boolean>) => {
      state.confirmEdit = action.payload;
    },
    setStatusSupplierNull: (state, action: PayloadAction<boolean>) => {
      state.statusSupplierNull = action.payload;
    },
    setStatusBlacklist: (state, action: PayloadAction<boolean>) => {
      state.statusBlacklist = action.payload;
    },
  },
});

export const {
  setOpenConfirmDeleteStore,
  setCloseConfirmDeleteStore,
  setRowStoreId,
  handleAddNewRow,
  handleRemoveRow,
  setFormStartDate,
  setFormEndDate,
  setProvinceInFo,
  setStoreInFo,
  setSkuInFo,
  setIsStoreExclusion,
  setIsStoreGroupExclusion,
  setIsCustomerExclusion,
  setIsCustomerGroupExclusion,
  setCrmTypeIdEdit,
  setGiftIdEdit,
  setOpenConfirmDelete,
  setCloseConfirmDelete,
  setOpenConfirmDeleteSku,
  setCloseConfirmDeleteSku,
  setIdPrizeDelete,
  setCountPrizeEvent,
  setCountPrizeProvince,
  setCountPrizeStore,
  setCountPrizeSku,
  setRowProvinceId,
  setRowSkuId,
  setStatusPrize,
  setPrizeQuantityChange,
  setOpenEditModal,
  setConfirmEdit,
  setStatusSupplierNull,
  setStatusBlacklist,
} = eventPrizeQ1Slice.actions;

export const setProvinceInFoSelector = (state: RootState) =>
  state.eventPrizeQ1.provinceInFo;
export const setStoreInFoSelector = (state: RootState) => state.eventPrizeQ1.storeInFo;
export const setSkuInFoSelector = (state: RootState) => state.eventPrizeQ1.skuInFo;

export default eventPrizeQ1Slice.reducer;
