import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/common/redux/store';

type StateProps = {
  giftName?: string;
  fromDate?: Date | null;
  toDate?: Date | null;
};

export const initialState: StateProps = {
  giftName: '',
  fromDate: null,
  toDate: null,
};

export const eventHistoryPrize = createSlice({
  name: 'eventAdmin',
  initialState,
  reducers: {
    setSeacrhParams: (state, action: PayloadAction<StateProps>) => {
      state.giftName = action.payload.giftName;
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
  },
});

export const { setSeacrhParams } = eventHistoryPrize.actions;

export const searchParamsSelector = (state: RootState) => {
  return {
    giftName: state.eventHistoryPrizeV2.giftName,
    fromDate: state.eventHistoryPrizeV2.fromDate,
    toDate: state.eventHistoryPrizeV2.toDate,
  };
};

export default eventHistoryPrize.reducer;
