import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// config
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_AUTH, PATH_DASHBOARD } from './paths';
import GiftCategoriesList from 'src/gift/gift-category';
import CategoryEdit from 'src/gift/gift-category-edit';
import FiltersList from 'src/filter/filter-list';
import FilterEdit from 'src/filter/filter-edit';
import HiddenTagsList from 'src/hiddenTag/hidden-tag-list';
import HiddenTagEdit from 'src/hiddenTag/hidden-tag-edit';
import CoinList from 'src/coin/order-list';
import FilterDetail from 'src/filter/filter-detail';
import OrderEditShip from 'src/orders/orders-ship';
import GiftPreOrdersList from 'src/gift/gift-preorder';
import UserGiftList from 'src/user-gift/user-gift-list';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  const { pathname } = useLocation();

  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: 'reset-password',
          element: <ResetPassword />,
        },
        {
          path: 'event-remaining/:id',
          element: <EventRemaining />,
        },
        {
          path: 'event-remaining/:id/webapp',
          element: <EventRemainingWebApp />,
        },
        {
          path: PATH_AUTH.eventQ4WebviewCls,
          element: <EventQ4WebviewCls />,
        },
        {
          path: PATH_AUTH.eventQ1WebviewList,
          element: <EventQ1WebviewList />,
        },
        {
          path: PATH_AUTH.eventQ1WebviewCls,
          element: <EventWebviewCls />,
        },
        {
          path: PATH_AUTH.environmentProtectionWebview,
          element: <EnvironmentProtectionWebview />,
        },
        {
          path: PATH_AUTH.webViewExchangePhoneNumber,
          element: <WebviewExchangePhoneNumber />,
        },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'shop-invitation/:id',
          element: <ShopInvitation />,
        },
        // STORE
        {
          path: '',
          children: [
            { element: <Navigate to="/dashboard/store" replace />, index: true },
            { path: 'stories', element: <ListStore /> },
            { path: 'stories/:id', element: <EditStore /> },
          ],
        },
        {
          path: '',
          children: [
            { element: <Navigate to="/" replace />, index: true },
            { path: 'admins', element: <AdminList /> },
            { path: 'admins/create', element: <AddNewAdmin /> },
            { path: 'admins/:id', element: <EditAdmin /> },
          ],
        },
        {
          path: '',
          children: [
            {
              element: <Navigate to="/dashboard/event-promotion-IV" replace />,
              index: true,
            },
            { path: 'event-promotion-IV', element: <ListEventPromotion /> },
            { path: 'event-promotion-IV/:id', element: <ViewEventPromotion /> },
            { path: 'event-promotion-IV/edit/:id', element: <EditEventPromotion /> },
            { path: 'event-promotion-IV/new', element: <AddEventPromotion /> },
          ],
        },
        {
          path: '',
          children: [
            { element: <Navigate to="/dashboard/event" replace />, index: true },
            { path: 'event-history', element: <History /> },
            { path: 'event-list-prize', element: <ListPrize /> },
            { path: 'event-create-prize/:id', element: <CreatePrize /> },
            // { path: 'event-list-prize', element: <ListPrize /> },
            { path: 'event/event-prize-edit/:id', element: <EditEventPrize /> },
            { path: 'event-list-prize/event-:id', element: <ListPrize /> },
          ],
        },
        {
          path: '',
          children: [
            { element: <Navigate to="/dashboard/event-q1-prize" replace />, index: true },
            { path: PATH_DASHBOARD.eventPrizeQ1.list, element: <ListEventQ1Prize /> },
            {
              path: PATH_DASHBOARD.eventPrizeQ1.edit,
              element: <EditEventPrizeQ1 />,
            },
            {
              path: PATH_DASHBOARD.eventPrizeQ1.create,
              element: <CreateEventPrizeQ1 />,
            },
            {
              path: PATH_DASHBOARD.eventPrizeQ1.detail,
              element: <EventQ1PrizeDetail />,
            },
          ],
        },
        {
          path: '',
          children: [
            {
              element: <Navigate to="/dashboard/event-quarter-one" />,
              index: true,
            },
            { path: 'event-quarter-one', element: <ManageListEvent /> },
            { path: 'add-new-event', element: <ManageCreateEvent /> },
            { path: 'event-quarter-one/:id', element: <ManageEditEvent /> },
            { path: 'event-quarter-one/view/:id', element: <ManageViewEvent /> },
          ],
        },
        {
          path: '',
          children: [
            {
              element: <Navigate to="/dashboard/event-q1-groupEvent" replace />,
              index: true,
            },
            { path: 'event-q1-groupEvent/list', element: <ListGroupEvent /> },
            { path: 'event-q1-groupEvent/add', element: <AddGroupEvent /> },
            { path: 'event-q1-groupEvent/:id', element: <EditGroupEvent /> },
            { path: 'event-q1-groupEvent/view/:id', element: <ViewGroupEvent /> },
          ],
        },
        {
          path: 'event-history-prize/list',
          element: <History />,
        },
        {
          path: 'event-history-prize-v2/list',
          element: <EventHistoryPrizeV2 />,
        },
        {
          path: '',
          children: [
            {
              element: <Navigate to="/dashboard/event-add-can" replace />,
              index: true,
            },
            { path: 'event-add-can', element: <EventAddCanList /> },
            { path: 'event-add-can/create', element: <EventAddCanCreate /> },
            { path: 'event-add-can/edit/:id', element: <EventAddCanEdit /> },
            { path: PATH_DASHBOARD.eventAddCan.detail, element: <EventAddCanDetail /> },
          ],
        },
        {
          path: PATH_DASHBOARD.campaign_manage.root,
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.campaign_manage.root} replace />,
              index: true,
            },
            { path: PATH_DASHBOARD.campaign_manage.list, element: <CampaignList /> },
            { path: PATH_DASHBOARD.campaign_manage.create, element: <CampaignCreate /> },
            { path: PATH_DASHBOARD.campaign_manage.edit, element: <CampaignEdit /> },
            { path: PATH_DASHBOARD.campaign_manage.detail, element: <CampaignDetail /> },
            {
              path: PATH_DASHBOARD.campaign_manage.listGroupUser,
              element: <ListGroupUser />,
            },
            {
              path: PATH_DASHBOARD.campaign_manage.createGroupUser,
              element: <CreateGroupUser />,
            },
            {
              path: 'edit-group-user/:id',
              element: <EditGroupUser />,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.floatingButton.root,
          children: [
            // {
            //   element: <Navigate to={PATH_DASHBOARD.floatingButton.root} replace />,
            //   index: true,
            // },
            { path: PATH_DASHBOARD.floatingButton.root, element: <FloatingButtonList /> },
            { path: PATH_DASHBOARD.floatingButton.edit, element: <FloatingButtonEdit /> },
          ],
        },
        {
          path: PATH_DASHBOARD.banner_manage.root,
          children: [
            {
              element: <Navigate to={PATH_DASHBOARD.banner_manage.root} replace />,
              index: true,
            },
            { path: PATH_DASHBOARD.banner_manage.list, element: <BannerList /> },
            { path: PATH_DASHBOARD.banner_manage.create, element: <BannerCreate /> },
            { path: PATH_DASHBOARD.banner_manage.edit, element: <BannerEdit /> },
          ],
        },
        {
          path: '',
          children: [
            { element: <Navigate to="/" replace />, index: true },
            { path: PATH_DASHBOARD.giftAdmin.list, element: <GiftList /> },
            { path: PATH_DASHBOARD.giftAdmin.orderList, element: <GiftOrderList /> },
            { path: PATH_DASHBOARD.giftAdmin.create, element: <GiftCreate /> },
            { path: PATH_DASHBOARD.giftAdmin.edit, element: <GiftEdit /> },
            {
              path: PATH_DASHBOARD.giftAdmin.categoryList,
              element: <GiftCategoriesList />,
            },
            {
              path: PATH_DASHBOARD.giftAdmin.categoryCreate,
              element: <CategoryEdit />,
            },
            {
              path: PATH_DASHBOARD.giftAdmin.categoryEdit,
              element: <CategoryEdit />,
            },
            {
              path: PATH_DASHBOARD.giftAdmin.preOrderList,
              element: <GiftPreOrdersList />,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.orderAdmin.root,
          children: [
            { path: PATH_DASHBOARD.orderAdmin.root, element: <OrderList /> },
            { path: PATH_DASHBOARD.orderAdmin.edit, element: <OrderEdit /> },
            { path: PATH_DASHBOARD.orderAdmin.editShowShip, element: <OrderEditShip /> },
          ],
        },
        {
          path: PATH_DASHBOARD.coinAdmin.root,
          children: [{ path: PATH_DASHBOARD.coinAdmin.root, element: <CoinList /> }],
        },
        {
          path: PATH_DASHBOARD.userGiftAdmin.root,
          children: [
            { path: PATH_DASHBOARD.userGiftAdmin.root, element: <UserGiftList /> },
          ],
        },
        {
          path: '',
          children: [
            { element: <Navigate to="/" replace />, index: true },
            {
              path: PATH_DASHBOARD.filterAdmin.root,
              element: <FiltersList />,
            },
            {
              path: PATH_DASHBOARD.filterAdmin.filterDetail,
              element: <FilterDetail />,
            },
            {
              path: PATH_DASHBOARD.filterAdmin.filterEdit,
              element: <FilterEdit />,
            },
            {
              path: PATH_DASHBOARD.filterAdmin.filterCreate,
              element: <FilterEdit />,
            },
          ],
        },
        {
          path: '',
          children: [
            { element: <Navigate to="/" replace />, index: true },
            {
              path: PATH_DASHBOARD.hiddenTagsAdmin.root,
              element: <HiddenTagsList />,
            },
            {
              path: PATH_DASHBOARD.hiddenTagsAdmin.hiddenTagsCreate,
              element: <HiddenTagEdit />,
            },
            {
              path: PATH_DASHBOARD.hiddenTagsAdmin.hiddenTagsEdit,
              element: <HiddenTagEdit />,
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',

      children: [
        {
          path: '',
          children: [
            { element: <Navigate to="/dashboard/stories" replace />, index: true },
            { path: 'stories', element: <ListStore /> },
          ],
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
// login
const Login = Loadable(lazy(() => import('../../auth/login/Login')));
const EventRemaining = Loadable(
  lazy(() => import('../../eventRemaining/EventRemainingDetail'))
);
const EventRemainingWebApp = Loadable(
  lazy(() => import('../../eventRemaining/EventReamingDetailWebApp'))
);
const EventQ4WebviewCls = Loadable(
  lazy(() => import('../../event-q4-webview-cls/index'))
);
const EventQ1WebviewList = Loadable(
  lazy(() => import('../../event-q1-webview-list/index'))
);
const EventWebviewCls = Loadable(lazy(() => import('../../event-q1-webview-cls/index')));
const ForgotPassword = Loadable(
  lazy(() => import('../../auth/forgot-password/ForgotPassword'))
);
const ResetPassword = Loadable(
  lazy(() => import('../../auth/reset-password/ResetPassword'))
);
const EnvironmentProtectionWebview = Loadable(
  lazy(() => import('../../environment-protection-webview/index'))
);
const WebviewExchangePhoneNumber = Loadable(
  lazy(() => import('../../webview-exchange-phone-number'))
);

// STORE ADMIN
const ListStore = Loadable(
  lazy(() => import('../../store-admin/storeAdmin-page/ListStoreAdmin'))
);
const EditStore = Loadable(
  lazy(() => import('../../store-admin/storeAdmin-page/EditStoreAdmin'))
);

// EVENT ADMIN
const History = Loadable(lazy(() => import('../../event/event-history-prize/index')));
const ListPrize = Loadable(lazy(() => import('../../event/list-prize/index')));
const CreatePrize = Loadable(
  lazy(() => import('../../event/event-history-prize/history-prize-create/create'))
);
const EditEventPrize = Loadable(lazy(() => import('../../event/edit-event-prize/index')));

// EVEN HISTORY PRICE V2
const EventHistoryPrizeV2 = Loadable(
  lazy(() => import('../../event-history-prize-v2/index'))
);

// EVENT Q1 PRIZE
const ListEventQ1Prize = Loadable(lazy(() => import('../../event-prize-q1/list')));
const EditEventPrizeQ1 = Loadable(lazy(() => import('../../event-prize-q1/edit')));
const CreateEventPrizeQ1 = Loadable(lazy(() => import('../../event-prize-q1/create')));
const EventQ1PrizeDetail = Loadable(lazy(() => import('../../event-prize-q1/detail')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));

// shop invitation
const ShopInvitation = Loadable(
  lazy(() => import('src/shop-invitation/components/ShopInvitation'))
);

// EVENT_PROMOTION_IV

const ListEventPromotion = Loadable(
  lazy(() => import('../pages/event-promotion-IV/listEventPromotion'))
);

const ViewEventPromotion = Loadable(
  lazy(() => import('../pages/event-promotion-IV/viewEventPromotion'))
);
const EditEventPromotion = Loadable(
  lazy(() => import('../pages/event-promotion-IV/editEventPromotion'))
);
const AddEventPromotion = Loadable(
  lazy(() => import('../pages/event-promotion-IV/addEventPromotion'))
);

const ListGroupEvent = Loadable(
  lazy(() => import('../../event-q1-groupEvent/list-group-event/index'))
);
const AddGroupEvent = Loadable(
  lazy(() => import('../../event-q1-groupEvent/add-group-event/index'))
);
const EditGroupEvent = Loadable(
  lazy(() => import('../../event-q1-groupEvent/edit-group-event/index'))
);
const ViewGroupEvent = Loadable(
  lazy(() => import('../../event-q1-groupEvent/view-group-event/index'))
);
// ADMIN
const AdminList = Loadable(lazy(() => import('../../admin/admin-pages/AdminList')));
const AddNewAdmin = Loadable(lazy(() => import('../../admin/admin-pages/AddNewAdmin')));
const EditAdmin = Loadable(lazy(() => import('../../admin/admin-pages/EditAdmin')));

// MANAGE_EVENT_QUARTER_ONE
const ManageListEvent = Loadable(
  lazy(() => import('../../manage-event-quarter-one/manage-list-event/index'))
);
const ManageCreateEvent = Loadable(
  lazy(() => import('../../manage-event-quarter-one/manage-create-event/index'))
);
const ManageViewEvent = Loadable(
  lazy(() => import('../../manage-event-quarter-one/manage-view-event/index'))
);

// EVENT_HISTORY_PRIZE_Q1
// const ListHistoryPrize = Loadable(
//   lazy(() => import('../../event-history-prize/list-event-history-prize/index'))
// );
const ManageEditEvent = Loadable(
  lazy(() => import('../../manage-event-quarter-one/manage-edit-event/index'))
);

// EVENT_ADD_CAN
const EventAddCanList = Loadable(lazy(() => import('../../event-add-can/list/index')));
const EventAddCanCreate = Loadable(
  lazy(() => import('../../event-add-can/create/index'))
);
const EventAddCanEdit = Loadable(lazy(() => import('../../event-add-can/edit/index')));
const EventAddCanDetail = Loadable(
  lazy(() => import('../../event-add-can/detail/index'))
);

// campaign manage
const CampaignList = Loadable(
  lazy(() => import('../../campaign-manage/campaign-list/index'))
);
const CampaignCreate = Loadable(
  lazy(() => import('../../campaign-manage/campaign-create/index'))
);
const CampaignEdit = Loadable(
  lazy(() => import('../../campaign-manage/campaign-edit/index'))
);
const CampaignDetail = Loadable(
  lazy(() => import('../../campaign-manage/campaign-detail/index'))
);

// Group-user
const ListGroupUser = Loadable(
  lazy(() => import('src/campaign-manage/groupUser-manage/list-groupUser/index'))
);
const CreateGroupUser = Loadable(
  lazy(() => import('src/campaign-manage/groupUser-manage/create-groupUser/index'))
);
const EditGroupUser = Loadable(
  lazy(() => import('src/campaign-manage/groupUser-manage/edit-groupUser/index'))
);

// floating-button
const FloatingButtonList = Loadable(
  lazy(() => import('src/floating-button-manage/floating-button-list/index'))
);
const FloatingButtonEdit = Loadable(
  lazy(() => import('src/floating-button-manage/floating-button-edit/index'))
);
// banner manage
const BannerList = Loadable(lazy(() => import('../../banner-manage/banner-list/index')));
const BannerCreate = Loadable(
  lazy(() => import('../../banner-manage/banner-create/index'))
);
const BannerEdit = Loadable(lazy(() => import('../../banner-manage/banner-edit/index')));

// Gifts manage
const GiftList = Loadable(lazy(() => import('../../gift/gift-list/index')));
const GiftOrderList = Loadable(lazy(() => import('../../gift/gift-order-list/index')));
const GiftCreate = Loadable(lazy(() => import('../../gift/gift-create/index')));
const GiftEdit = Loadable(lazy(() => import('../../gift/gift-edit/index')));

// Orders manage
const OrderList = Loadable(lazy(() => import('../../orders/order-list/index')));
const OrderEdit = Loadable(lazy(() => import('../../orders/orders-edit/index')));
